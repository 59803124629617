import React from 'react'
import styled from 'styled-components'
import { theme, palette } from 'styled-tools'

import Heading from '../components/parts/Heading'
import Paragraph from '../components/parts/Paragraph'
import Button from '../components/parts/Button'

export const StepWrapper = styled.div`
  margin-bottom: 3rem;
`

export const StepInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export const StepFlippedInfoContainer = styled((props) => <StepInfoContainer {...props} />)`
  align-items: flex-end;
  text-align: right;

  ${theme('media.smDown')} {
    align-items: flex-start;
    text-align: left;
  }
`

export const StepHeadingStyled = styled(({ flipDesktop, ...props }) => <Heading {...props} />)`
  margin: 1rem 0;
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.2;
  letter-spacing: 1.25px;
  transform: ${(props) => {
    const translateSize = 8
    return props.flipDesktop
      ? `translateX(${translateSize}rem)`
      : `translateX(-${translateSize}rem)`
  }};

  ${theme('media.mdDown')} {
    font-size: 2rem;
  }

  ${theme('media.smDown')} {
    transform: unset;
  }
`

export const StepParagraphStyled = styled(Paragraph)`
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
`

export const HeadingStyled = styled(Heading)`
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.3;
  letter-spacing: 1.25px;

  ${theme('media.mdDown')} {
    font-size: 2rem;
  }
`

export const ParagraphStyled = styled(Paragraph)`
  max-width: 35rem;
  margin: 2.5rem 0;
  padding-bottom: 2.5rem;
  font-weight: bold;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.5;
  letter-spacing: 0.4px;
`

export const ButtonWrapper = styled.div`
  margin: 2.5rem 0;
`

export const ButtonStyled = styled(Button)`
  width: fit-content;
  padding: 1.25rem;
  font-weight: normal;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 2.25;
  letter-spacing: 0.2px;
  white-space: nowrap;

  & span {
    padding-left: 1.25rem;
  }
`

export const SectionPress = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  align-items: center;
  padding: 0 1rem;

  ${theme('media.mdUp')} {
    flex-direction: row;
    padding: 0;
  }
`

export const SectionPressHeading = styled.div`
  ${theme('media.mdUp')} {
    max-width: 300px;
  }
`

export const PressContentContainer = styled.div`
  width: 100%;
`

export const VerticalLine = styled.div`
  display: none;
  height: 150px;
  margin: auto 3rem;
  border-left: 3px solid ${palette('black')};

  ${theme('media.mdUp')} {
    display: inline-block;
  }
`
