import styled from 'styled-components'
import { theme } from 'styled-tools'

const PressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  align-items: center;

  > div {
    width: 50%;
    max-width: 200px;
    margin: 0 auto;
  }

  ${theme('media.xsUp')} {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    
    > div {
      width: 100%;
    }
  }

  ${theme('media.smUp')} {
    grid-template-columns: repeat(4, minmax(auto, 1fr));
    gap: 5%;

    > div {
      max-width: unset;
      margin: unset;
    }
  }
`

export default PressWrapper
