import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import theme from '../config/theme'
import {
  StepFlippedInfoContainer,
  StepInfoContainer,
  StepWrapper,
  StepHeadingStyled,
  StepParagraphStyled,
  HeadingStyled,
  ParagraphStyled,
  SectionPress,
  SectionPressHeading,
  ButtonWrapper,
  ButtonStyled,
  VerticalLine,
  PressContentContainer,
} from '../styles/ueber-uns'

import Page from '../components/layouts/Page'
import Container from '../components/parts/Container'
import Columns from '../components/parts/Columns'
import Column from '../components/parts/Column'
import Press from '../components/parts/Press'
import StoneBg from '../components/parts/StoneBg'
import Mark, { markImages } from '../components/parts/Mark'
import Pattern from '../components/parts/Pattern'
import SvgPattern from '../static/images/patterns/seven.svg'
import DownloadIcon from '../static/images/icons/download.svg'

const data = [
  {
    title: 'Wir sind StrollMe',
    description: [
      'Wir, Sebastian und Timon, sind selbst Eltern und neben Freude und Stolz bedeutet das Elternsein für uns auch, unserer Verantwortung gerecht zu werden. Dem Nachwuchs irgendwann eine Welt zu hinterlassen, auf der es sich zu leben lohnt.',
      'Daher ist es uns ein Anliegen, den eigenen ökologischen Fußabdruck so gering wie möglich zu halten.',
    ],
  },
  {
    title: 'Die Idee',
    description: [
      'Wir sind der Meinung, dass nicht für jedes neugeborene Kind ein neuer Kinderwagen produziert werden muss. Dass wir Ressourcen schlau nutzen sollten. Und dass diese Ressourcen im Kreislauf bleiben sollten.',
      'Genau das versuchen wir mit StrollMe. Einen Kinderwagen, den ich genau so lange nutze, wie ich ihn wirklich brauche. Ihn danach nicht im Keller vergesse, sondern ihn wieder dem Kreislauf zuführe. Er aufbereitet wird, damit ihm andere Eltern eine zweite Nutzung ermöglichen. Und das er irgendwann, wenn er abgenutzt wird, wieder recycelt wird und aus ihm ein neuer Kinderwagen entsteht.',
    ],
  },
  {
    title: 'Unsere Vision',
    description: [
      'Wir wollen Teil einer Bewegung sein. Wir wollen diese Bewegung in deinen Alltag mit deinem Kind bringen. Das Bewusstsein schaffen, dass wir genau für dieses Kind anfangen müssen, etwas zu ändern. Unsere Ressourcen schlauer zu nutzen.',
      'Es soll nicht bei einem Kinderwagen bleiben. Wir wollen, dass alle Eltern Zugang zu nachhaltiger Kinder-Mobilität zu einem vernünftigen Preis haben. Kinderfahrräder, Lastenräder – die Liste auf unserem Tisch ist lang. Stay tuned. Und tragt euch in unseren Newsletter ein, um nichts zu verpassen.',
    ],
  },
]

const StepAboutUs = ({
  image, title, description, flipDesktop,
}) => {
  const InfoContainerComponent = flipDesktop ? StepFlippedInfoContainer : StepInfoContainer

  return (
    <StepWrapper>
      <Columns flipDesktop={flipDesktop}>
        <Column>
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
        </Column>
        <Column>
          <InfoContainerComponent>
            <StepHeadingStyled level={2} flipDesktop={flipDesktop}>
              {title}
            </StepHeadingStyled>
            <div>
              {description.map((content) => (
                <StepParagraphStyled key={content}>{content}</StepParagraphStyled>
              ))}
            </div>
          </InfoContainerComponent>
        </Column>
      </Columns>
    </StepWrapper>
  )
}

StepAboutUs.propTypes = {
  image: PropTypes.objectOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  flipDesktop: PropTypes.bool,
}

StepAboutUs.defaultProps = {
  flipDesktop: false,
}

const AboutUs = () => {
  const imagesData = useStaticQuery(graphql`
    {
      wirSindImage: file(
        relativePath: { eq: "february-shooting/strollme_shooting_Feb_21-276.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      dieIdeeImage: file(
        relativePath: { eq: "february-shooting/strollme_shooting_Feb_21-357.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      unsereVisionImage: file(
        relativePath: { eq: "february-shooting/strollme_shooting_Feb_21-408.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  `)
  const { wirSindImage, dieIdeeImage, unsereVisionImage } = imagesData

  const imagesArray = [wirSindImage, dieIdeeImage, unsereVisionImage]

  return (
    <Page title="Über uns" description="" backgroundColor={theme.palette.sand[0]}>
      <div
        style={{
          paddingTop: '10rem',
          backgroundColor: theme.palette.sand[0],
          position: 'relative',
        }}
      >
        <Container>
          <HeadingStyled level={1} style={{ maxWidth: '53rem' }}>
            <>
              <span style={{ color: theme.palette.green[0] }}>Unsere Mission?</span>
              <br />
              Dir zeigen, dass Nutzen nicht immer Kaufen bedeuten muss.
            </>
          </HeadingStyled>
          <ParagraphStyled>
            Wir glauben, dass die Welt bereit ist für ein Umdenken. Man kauft kein Auto mehr, man
            shared es. Ein Fahrrad musst du nicht besitzen, du nutzt es solange du es brauchst. Wir
            glauben, das geht auch mit Kinder-Mobilität. Und wir fangen mit dem Kinderwagen an.
          </ParagraphStyled>
        </Container>
        <Pattern top="0" right="0" transformX="40%">
          <SvgPattern />
        </Pattern>
      </div>
      <div
        style={{
          padding: '7rem 0',
        }}
      >
        <Container>
          {data.map(({ title, description }, index) => (
            <StepAboutUs
              key={title}
              image={imagesArray[index]}
              title={title}
              description={description}
              flipDesktop={index % 2 === 1}
            />
          ))}
        </Container>
      </div>
      <StoneBg>
        <div
          style={{
            padding: '5rem 0 2rem',
          }}
        >
          <Container>
            <SectionPress>
              <SectionPressHeading>
                <HeadingStyled level={3}>
                  Das sagt{' '}
                  <Mark
                    image={markImages.yellowNormal}
                    style={{ backgroundPosition: '0 100%', backgroundSize: '100%' }}
                  >
                    die Presse
                  </Mark>
                </HeadingStyled>

                <ButtonWrapper>
                  <ButtonStyled
                    href="https://storage.strollme.de/strollme_mediakit.zip"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <>
                      <DownloadIcon />
                      <span>Mediakit herunterladen</span>
                    </>
                  </ButtonStyled>
                </ButtonWrapper>
              </SectionPressHeading>
              <VerticalLine />
              <PressContentContainer>
                <Press />
              </PressContentContainer>
            </SectionPress>
          </Container>
        </div>
      </StoneBg>
    </Page>
  )
}

export default AboutUs
