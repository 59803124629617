import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PressWrapper from './styled'

const Press = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "//data/media//" } }
          sort: { fields: frontmatter___order }
        ) {
          nodes {
            html
            fileAbsolutePath
          }
        }
      }
    `
  )

  const markdownMedia = data.allMarkdownRemark.nodes

  return (
    <PressWrapper>
      {markdownMedia.map((item) => (
        // eslint-disable-next-line react/no-danger
        <div key={item.fileAbsolutePath} dangerouslySetInnerHTML={{ __html: item.html }} />
      ))}
    </PressWrapper>
  )
}

export default Press
